import React from 'react';
import '../../Styles/About.css';

function About() {
  return (
    <section className="Main-body">
      <h2>За нас</h2>

      
      
      <p>
      КАТЕР 2004 ЕООД 
      е основан през 2004 г. и сме активни основно в рециклирането и търговията с отпадъци от метали. Фирмата разполага с машини и персонал за третиране на отпадъци от производство на метали. През последните 20 години се превърнахме във важен партньор на множество фабрики, както в Европа, така и в Азия.
      </p>

      <div className='picture'>
                
      </div>

    </section>
  );
}

export default About;
